import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import IntroSection from "../components/IntroSection"
import TeamSection from "../components/TeamSection"
import NeedFindingSection from "../components/NeedFindingSection"
import SolutionSection from "../components/SolutionSection"
import { Helmet } from "react-helmet"
import BuyScreen from "../components/BuyScreen"
import { Media } from "gatsby-plugin-fresnel"
import ParticlesBG from "../components/ParticlesBG"

const SecondPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mohammad Baqer</title>
      </Helmet>
      <Media lessThan="lg">
        <ParticlesBG black />
        <BuyScreen />
      </Media>
      <Media greaterThanOrEqual="lg">
        <IntroSection />
        <TeamSection />
        <NeedFindingSection />
        <SolutionSection />
      </Media>
    </>
  )
}

export default SecondPage
