import React from "react"
import styled from "styled-components"
import Frames from "./Frames"
import "./IntroSection.css"
import { Link } from "gatsby"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const TitleContainer = styled.div`
  background-color: #800000;
  min-width: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
`

const SubContainer = styled.div`
  flex: 0 0 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: relative;
`

const FramesContainer = styled.div`
  padding-top: 10px;
  overflow: hidden;
  flex: 0 0 60%;
`

const H1 = styled.h2`
  color: #fff;
  font-size: 8vh;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 900;
`

const H2 = styled.h2`
  color: #fff;
`

const H3 = styled.h3`
  color: #fff;
`

const P = styled.p`
  font-size: 16px;
  line-height: normal;
  color: #fff;
`

const OverviewSection = styled.div`
  background-color: #242536;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-left: 12%;
  padding-right: 12%;
`

const SubSection = styled.div`
  max-width: 35%;
  min-height: 230px;
  margin: 40px;
`

const BackP = styled.p`
  font-size: 2vh;
  color: white;
  position: absolute;
  display: flex;
  align-items: center;
  top: 3%;
  left: 8%;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    border-bottom: 2px solid white;
  }

  &,
  &::after {
    transition: all 0.07s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
`

class IntroSection extends React.Component {
  render() {
    return (
      <>
        <TitleContainer>
          <SubContainer>
            <Link to="/">
              <BackP>
                <img
                  style={{
                    height: "2.5vh",
                    width: "2.5vh",
                    marginBottom: "-0.5vh",
                  }}
                  src="/images/Back.svg"
                />
                &nbsp;Go Home
              </BackP>
            </Link>
            <H1>
              Degree Planner
              <br /> Website
            </H1>
            <H2>UX Case Study</H2>
            <div id="icon-scroll" />
          </SubContainer>
          <FramesContainer>
            <Frames />
          </FramesContainer>
        </TitleContainer>
        <Container>
          <OverviewSection>
            <SubSection>
              <H3>Overview</H3>
              <P>
                Rose-Hulman has a degree planner website that allows students to
                plan out their 4-year plan. However, the website is outdated and
                has a lot of space to improve. As a result, our team picked it
                as the subject of our independent study project. This is a case
                study to show our process of developing a better user experience
                for the website.
              </P>
            </SubSection>
            <SubSection>
              <H3>My Role</H3>
              <P>
                I was one of the two students responsible for this project. We
                worked as UX designers, and I as the team leader. In addition to
                designing the UX, I managed the project's plan, coordinated with
                another senior capstone project team to combine our project with
                theirs, and made reports of the project to our advisor.
              </P>
            </SubSection>
          </OverviewSection>
        </Container>
      </>
    )
  }
}

export default IntroSection
